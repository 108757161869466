<template>
    <CatalogList />
</template>

<script>
import {
    mapActions
} from 'vuex'

import CatalogList from '../components/CatalogList.vue'

export default {
    components: {
        CatalogList
    },
    data: () => ({
    }),
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
        //this.getUserInfo(this)
    },
}
</script>

