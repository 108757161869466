<template>
<div>
    <v-breadcrumbs :items="breadItems" class="pl-4">
        <template v-slot:divider>
            <v-icon>mdi-arrow-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="px-3">
    <List />
    </div>
</div>
</template>

<script>
import {
    HTTP
} from '../http-common'

import List from './List.vue'

export default {
    components: {
        List
    },
    data: () => ({
    }),
    props: {},
    methods: {},
    computed: {
        breadItems() {
            let items = []
            const store = this.$store.state.main.breadCrumbs[this.$route.params.id] || {}
            if (store.pppp_id) {
                items.push({
                    text: store.pppp_name,
                    'disabled': false,
                    to: '/list/' + store.pppp_id,
                })
            }
            if (store.ppp_id) {
                items.push({
                    text: store.ppp_name,
                    'disabled': false,
                    to: '/list/' + store.ppp_id,
                })
            }
            if (store.pp_id) {
                items.push({
                    text: store.pp_name,
                    'disabled': false,
                    to: '/list/' + store.pp_id,
                })
            }
            if (store.p_id) {
                items.push({
                    text: store.p_name,
                    'disabled': false,
                    to: '/list/' + store.p_id,
                })
            }
            if (store.id) {
                items.push({
                    text: store.name,
                    'disabled': false,
                    to: '/list/' + store.id,
                })
            }
            return items
        }

    }
}
</script>
